<template>
    <ToastClose
        v-bind="delegatedProps"
        :class="
            cn(
                'absolute right-2 top-2 rounded-md p-1 text-gray-950/50 hover:text-gray-950 focus:outline-none focus:ring-2',
                props.class
            )
        ">
        <i class="text-xl fass fa-xmark"></i>
    </ToastClose>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { ToastClose, type ToastCloseProps } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps<
    ToastCloseProps & {
        class?: HTMLAttributes["class"];
    }
>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>
