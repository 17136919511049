import type { BadgeVariants } from "~/components/ui/badge";

export enum ANALYTICS_SOURCE {
    BOM = "BOM",
    BOM_TOOL = "BOMTool",
    EMBEDDABLE_BOM_TOOL = "EmbeddableBOM",
    LEGACY_DSS = "API",
    GLOBAL = "GlobalWebsite",
    API_V1 = "InventoryAPI",
    API_V2 = "APIv2",
    DSS = "DSS",
    PART_CATEGORY = "PartCategory",
    PART_DETAIL = "PartDetail",
    PRODUCT_SERIES = "ProductSeries",
    WEBSITE = "Website",
}

export const BOM_TOOL = {
    BOM_PERMISSION_LEVELS: {
        PUBLIC: 0,
        VIEWER: 1,
        EDITOR: 2,
        OWNER: 3,
    },
    LOCK_POLL_MINUTES: 1,
    STAGGER_PART_SEARCHES_MS: 500,
    SORT_ORDER: {
        Custom: 1,
        PartNum_Asc: 2,
        PartNum_Desc: 3,
        Manufacturer_Asc: 4,
        Manufacturer_Desc: 5,
    },
    DIRECT_DOWNLOAD_MAX_PARTS: 250,
    STATUS_CHECK_DELAY_SECONDS: 1,
    DEFAULT_PAGE_SIZE: 50,
    BATCH_SIZE: 5,
    AUTHENTICATED_BATCH_SIZE: 20,
    BOM_ALERTS_FREQUENCY: {
        DAILY: 1,
        WEEKLY: 2,
    },
    MAX_PARTS: 100,
    MAX_EXTENDED_PARTS: 1000,
};

export const SEARCH = {
    MAX_DESCRIPTION_LENGTH: 200,
    DROPDOWN_TYPE: "dropdown",
    MULTI_TYPE: "multi",
};

export const SCREEN_BREAKPOINTS = {
    sm: 768,
    md: 992,
    lg: 1200,
};

export const LANGUAGE_CODES = ["de", "en", "es", "fr", "it", "pt", "ja", "zh-hans", "zh-hant"];

export type RiskOption = {
    label: string;
    variant: NonNullable<BadgeVariants["variant"]>;
    fill: NonNullable<BadgeVariants["fill"]>;
    tooltip: string;
};

export const LIFECYCLE_RISK_OPTIONS: Record<number, RiskOption> = {
    0: {
        label: "Global.RiskLevelLow",
        variant: "success",
        fill: "dark",
        tooltip: "Global.LifecycleRiskLevelLowToolTip",
    },
    1: {
        label: "Global.RiskLevelLowMedium",
        variant: "success",
        fill: "light",
        tooltip: "Global.LifecycleRiskLevelLowMediumToolTip",
    },
    2: {
        label: "Global.RiskLevelMedium",
        variant: "warning",
        fill: "dark",
        tooltip: "Global.LifecycleRiskLevelMediumToolTip",
    },
    3: {
        label: "Global.RiskLevelHigh",
        variant: "danger",
        fill: "dark",
        tooltip: "Global.LifecycleRiskLevelHighToolTip",
    },
};

export const SUPPLYCHAIN_RISK_OPTIONS: Record<number, RiskOption> = {
    0: {
        label: "Global.RiskLevelLow",
        variant: "success",
        fill: "dark",
        tooltip: "Global.SupplyChainRiskLevelLowToolTip",
    },
    1: {
        label: "Global.RiskLevelLowMedium",
        variant: "success",
        fill: "light",
        tooltip: "Global.SupplyChainRiskLevelLowMediumToolTip",
    },
    2: {
        label: "Global.RiskLevelMedium",
        variant: "warning",
        fill: "dark",
        tooltip: "Global.SupplyChainRiskLevelMediumToolTip",
    },
    3: {
        label: "Global.RiskLevelHigh",
        variant: "danger",
        fill: "dark",
        tooltip: "Global.SupplyChainRiskLevelHighToolTip",
    },
};

export const REGION_SHORT_NAMES = {
    1: "AMERICAS",
    2: "CHINA",
    3: "APAC",
    4: "EMEA",
    5: "JAPAN",
};

// Source Types PriceAlert
export const MODIFIER_TYPE = {
    LESS_THAN: 1,
    GREATER_THAN: 2,
};

export const PART_MODEL_STATUS = {
    Unknown: -1,
    NotFound: 0,
    ModelsExist: 1,
};

export const MIN_SEARCH_LENGTH = 3;
export const CONTINUE_AS_GUEST_FLAG = "ecia-continue-as-guest";
export const CONTINUE_AS_GUEST_EXPIRATION = 90;
export const MAX_FILE_UPLOAD_MEGABYTES = 50;
