export const useStateQuery = () => useState<string>("query", () => "");

export const useStateCategoryFilter = () => useState<string>("categoryFilter", () => "");

export const useStateFilterOptions = () => useState<FilterOption[]>("filterOptions", () => []);

export const useStateFilterValues = () =>
    useState<FilterValues>("filterValues", () => ({ inStock: false, manufacturers: [], specs: [] }));

export const useStateIsAuthenticated = () => useState<boolean>("isAuthenticated", () => false);

export const useStateUserInfo = () =>
    useState<UserInfo>("userInfo", () => ({
        IsAdmin: false,
        IsDistributor: false,
        IsManufacturer: false,
        HasLeadTimeReporting: false,
        IsCookieConsentNeeded: false,
        AcceptsAnalyticsCookies: false,
    }));

export const useStateLocationInfo = () => useState<LocationInfo>("locationInfo", () => ({}));

export const useStateRegionInfo = () => useState<RegionInfo[]>("regionInfo", () => []);

export const useStateCurrencyInfo = () => useState<CurrencyInfo[]>("currencyInfo", () => []);

export const useStateGlobalConfig = () =>
    useState<GlobalConfig>("globalConfig", () => ({
        isAuthenticated: false,
        CDNUrl: "",
        CurrencyCode: "USD",
        LanguageCode: "en",
        Features: EMPTY_FEATURE_FLAGS,
        SearchText: "",
    }));

export const useStateNotificationIsOpen = () => useState<boolean>("notificationIsOpen", () => false);

export const useStateCategory = () => useState<Category | null>("category", () => null);

export const useStateDistributor = () => useState<DistributorDetails | null>("distributor", () => null);
