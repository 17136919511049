import { default as indexIoSMAuqTbwMeta } from "/src/Frontend/pages/index.vue?macro=true";
import { default as about_45usXFZL9iMdSTMeta } from "/src/Frontend/pages/about-us.vue?macro=true";
import { default as administration9GvxPpbVfKMeta } from "/src/Frontend/pages/administration.vue?macro=true";
import { default as all_45categoriesjgBMEgeyGtMeta } from "/src/Frontend/pages/all-categories.vue?macro=true";
import { default as bomug2aIqJrLSMeta } from "/src/Frontend/pages/bom.vue?macro=true";
import { default as componentsg6TN8HDQBAMeta } from "/src/Frontend/pages/components.vue?macro=true";
import { default as contact_45usULWwaXHrh0Meta } from "/src/Frontend/pages/contact-us.vue?macro=true";
import { default as landingAtVPvQXPTBMeta } from "/src/Frontend/pages/distributor/landing.vue?macro=true";
import { default as _91dist_93xCZm5ZastrMeta } from "/src/Frontend/pages/distributors/[dist].vue?macro=true";
import { default as indexa7OpAcZQAzMeta } from "/src/Frontend/pages/distributors/index.vue?macro=true";
import { default as _91part_93P27ZJOJTPLMeta } from "/src/Frontend/pages/featured-products/[part].vue?macro=true";
import { default as logout8bm8q3GgFuMeta } from "/src/Frontend/pages/logout.vue?macro=true";
import { default as landing5xopTAFYDPMeta } from "/src/Frontend/pages/manufacturer/landing.vue?macro=true";
import { default as _91mfr_93UInPoTwocPMeta } from "/src/Frontend/pages/manufacturers/[mfr].vue?macro=true";
import { default as index_46serverubPz7nePeAMeta } from "/src/Frontend/pages/manufacturers/index.server.vue?macro=true";

let _createIslandPage
async function createIslandPage (name) {
  _createIslandPage ||= await import("/src/Frontend/node_modules/nuxt/dist/components/runtime/server-component").then(r => r.createIslandPage)
  return _createIslandPage(name)
};
import { default as my_45accounteTgrILw3cmMeta } from "/src/Frontend/pages/my-account.vue?macro=true";
import { default as _91_46_46_46path_93dq4kdBthehMeta } from "/src/Frontend/pages/part-category/[...path].vue?macro=true";
import { default as indexEZ4HDs9HhsMeta } from "/src/Frontend/pages/part/[company]/[partNumber]/index.vue?macro=true";
import { default as modelspIuUzKq97BMeta } from "/src/Frontend/pages/part/[company]/[partNumber]/models.vue?macro=true";
import { default as privacy_45policy8way8Gyi0LMeta } from "/src/Frontend/pages/privacy-policy.vue?macro=true";
import { default as _91query_93KEpQFfGPqZMeta } from "/src/Frontend/pages/search/[query].vue?macro=true";
import { default as sign_45in4jakt0hYkvMeta } from "/src/Frontend/pages/sign-in.vue?macro=true";
import { default as sitemapJG1IIzz4suMeta } from "/src/Frontend/pages/sitemap.vue?macro=true";
import { default as terms_45and_45conditionsSmDXrnIOd6Meta } from "/src/Frontend/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___de",
    path: "/de/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___es",
    path: "/es/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___fr",
    path: "/fr/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___it",
    path: "/it/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___ja",
    path: "/ja/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___pt",
    path: "/pt/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___zh-Hans",
    path: "/zh-Hans/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "about-us___zh-Hant",
    path: "/zh-Hant/about-us",
    component: () => import("/src/Frontend/pages/about-us.vue")
  },
  {
    name: "administration___en",
    path: "/en/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___de",
    path: "/de/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___es",
    path: "/es/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___fr",
    path: "/fr/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___it",
    path: "/it/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___ja",
    path: "/ja/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___pt",
    path: "/pt/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___zh-Hans",
    path: "/zh-Hans/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "administration___zh-Hant",
    path: "/zh-Hant/administration",
    component: () => import("/src/Frontend/pages/administration.vue")
  },
  {
    name: "all-categories___en",
    path: "/en/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___de",
    path: "/de/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___es",
    path: "/es/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___fr",
    path: "/fr/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___it",
    path: "/it/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___ja",
    path: "/ja/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___pt",
    path: "/pt/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___zh-Hans",
    path: "/zh-Hans/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "all-categories___zh-Hant",
    path: "/zh-Hant/all-categories",
    component: () => import("/src/Frontend/pages/all-categories.vue")
  },
  {
    name: "bom___en",
    path: "/en/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___de",
    path: "/de/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___es",
    path: "/es/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___fr",
    path: "/fr/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___it",
    path: "/it/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___ja",
    path: "/ja/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___pt",
    path: "/pt/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___zh-Hans",
    path: "/zh-Hans/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "bom___zh-Hant",
    path: "/zh-Hant/bom",
    component: () => import("/src/Frontend/pages/bom.vue")
  },
  {
    name: "components___en",
    path: "/en/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___de",
    path: "/de/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___es",
    path: "/es/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___fr",
    path: "/fr/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___it",
    path: "/it/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___ja",
    path: "/ja/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___pt",
    path: "/pt/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___zh-Hans",
    path: "/zh-Hans/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "components___zh-Hant",
    path: "/zh-Hant/components",
    component: () => import("/src/Frontend/pages/components.vue")
  },
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___de",
    path: "/de/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___es",
    path: "/es/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___fr",
    path: "/fr/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___it",
    path: "/it/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___ja",
    path: "/ja/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___pt",
    path: "/pt/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___zh-Hans",
    path: "/zh-Hans/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "contact-us___zh-Hant",
    path: "/zh-Hant/contact-us",
    component: () => import("/src/Frontend/pages/contact-us.vue")
  },
  {
    name: "distributor-landing___en",
    path: "/en/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___de",
    path: "/de/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___es",
    path: "/es/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___fr",
    path: "/fr/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___it",
    path: "/it/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___ja",
    path: "/ja/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___pt",
    path: "/pt/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___zh-Hans",
    path: "/zh-Hans/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributor-landing___zh-Hant",
    path: "/zh-Hant/distributor/landing",
    component: () => import("/src/Frontend/pages/distributor/landing.vue")
  },
  {
    name: "distributors-dist___en",
    path: "/en/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___de",
    path: "/de/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___es",
    path: "/es/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___fr",
    path: "/fr/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___it",
    path: "/it/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___ja",
    path: "/ja/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___pt",
    path: "/pt/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___zh-Hans",
    path: "/zh-Hans/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors-dist___zh-Hant",
    path: "/zh-Hant/distributors/:dist()",
    component: () => import("/src/Frontend/pages/distributors/[dist].vue")
  },
  {
    name: "distributors___en",
    path: "/en/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___de",
    path: "/de/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___es",
    path: "/es/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___fr",
    path: "/fr/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___it",
    path: "/it/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___ja",
    path: "/ja/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___pt",
    path: "/pt/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___zh-Hans",
    path: "/zh-Hans/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "distributors___zh-Hant",
    path: "/zh-Hant/distributors",
    component: () => import("/src/Frontend/pages/distributors/index.vue")
  },
  {
    name: "featured-products-part___en",
    path: "/en/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___de",
    path: "/de/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___es",
    path: "/es/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___fr",
    path: "/fr/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___it",
    path: "/it/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___ja",
    path: "/ja/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___pt",
    path: "/pt/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___zh-Hans",
    path: "/zh-Hans/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "featured-products-part___zh-Hant",
    path: "/zh-Hant/featured-products/:part()",
    component: () => import("/src/Frontend/pages/featured-products/[part].vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___zh-Hans",
    path: "/zh-Hans",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "index___zh-Hant",
    path: "/zh-Hant",
    meta: indexIoSMAuqTbwMeta || {},
    component: () => import("/src/Frontend/pages/index.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___de",
    path: "/de/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___es",
    path: "/es/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___fr",
    path: "/fr/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___it",
    path: "/it/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___ja",
    path: "/ja/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___pt",
    path: "/pt/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___zh-Hans",
    path: "/zh-Hans/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "logout___zh-Hant",
    path: "/zh-Hant/logout",
    component: () => import("/src/Frontend/pages/logout.vue")
  },
  {
    name: "manufacturer-landing___en",
    path: "/en/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___de",
    path: "/de/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___es",
    path: "/es/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___fr",
    path: "/fr/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___it",
    path: "/it/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___ja",
    path: "/ja/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___pt",
    path: "/pt/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___zh-Hans",
    path: "/zh-Hans/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturer-landing___zh-Hant",
    path: "/zh-Hant/manufacturer/landing",
    component: () => import("/src/Frontend/pages/manufacturer/landing.vue")
  },
  {
    name: "manufacturers-mfr___en",
    path: "/en/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___de",
    path: "/de/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___es",
    path: "/es/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___fr",
    path: "/fr/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___it",
    path: "/it/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___ja",
    path: "/ja/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___pt",
    path: "/pt/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___zh-Hans",
    path: "/zh-Hans/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers-mfr___zh-Hant",
    path: "/zh-Hant/manufacturers/:mfr()",
    component: () => import("/src/Frontend/pages/manufacturers/[mfr].vue")
  },
  {
    name: "manufacturers___en",
    path: "/en/manufacturers",
    component: () => createIslandPage("manufacturers___en")
  },
  {
    name: "manufacturers___de",
    path: "/de/manufacturers",
    component: () => createIslandPage("manufacturers___de")
  },
  {
    name: "manufacturers___es",
    path: "/es/manufacturers",
    component: () => createIslandPage("manufacturers___es")
  },
  {
    name: "manufacturers___fr",
    path: "/fr/manufacturers",
    component: () => createIslandPage("manufacturers___fr")
  },
  {
    name: "manufacturers___it",
    path: "/it/manufacturers",
    component: () => createIslandPage("manufacturers___it")
  },
  {
    name: "manufacturers___ja",
    path: "/ja/manufacturers",
    component: () => createIslandPage("manufacturers___ja")
  },
  {
    name: "manufacturers___pt",
    path: "/pt/manufacturers",
    component: () => createIslandPage("manufacturers___pt")
  },
  {
    name: "manufacturers___zh-Hans",
    path: "/zh-Hans/manufacturers",
    component: () => createIslandPage("manufacturers___zh-Hans")
  },
  {
    name: "manufacturers___zh-Hant",
    path: "/zh-Hant/manufacturers",
    component: () => createIslandPage("manufacturers___zh-Hant")
  },
  {
    name: "my-account___en",
    path: "/en/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___de",
    path: "/de/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___es",
    path: "/es/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___fr",
    path: "/fr/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___it",
    path: "/it/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___ja",
    path: "/ja/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___pt",
    path: "/pt/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___zh-Hans",
    path: "/zh-Hans/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "my-account___zh-Hant",
    path: "/zh-Hant/my-account",
    component: () => import("/src/Frontend/pages/my-account.vue")
  },
  {
    name: "part-category-path___en",
    path: "/en/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___de",
    path: "/de/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___es",
    path: "/es/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___fr",
    path: "/fr/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___it",
    path: "/it/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___ja",
    path: "/ja/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___pt",
    path: "/pt/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___zh-Hans",
    path: "/zh-Hans/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-category-path___zh-Hant",
    path: "/zh-Hant/part-category/:path(.*)*",
    component: () => import("/src/Frontend/pages/part-category/[...path].vue")
  },
  {
    name: "part-company-partNumber___en",
    path: "/en/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___de",
    path: "/de/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___es",
    path: "/es/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___fr",
    path: "/fr/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___it",
    path: "/it/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___ja",
    path: "/ja/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___pt",
    path: "/pt/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___zh-Hans",
    path: "/zh-Hans/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber___zh-Hant",
    path: "/zh-Hant/part/:company()/:partNumber()",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/index.vue")
  },
  {
    name: "part-company-partNumber-models___en",
    path: "/en/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___de",
    path: "/de/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___es",
    path: "/es/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___fr",
    path: "/fr/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___it",
    path: "/it/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___ja",
    path: "/ja/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___pt",
    path: "/pt/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___zh-Hans",
    path: "/zh-Hans/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "part-company-partNumber-models___zh-Hant",
    path: "/zh-Hant/part/:company()/:partNumber()/models",
    component: () => import("/src/Frontend/pages/part/[company]/[partNumber]/models.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___ja",
    path: "/ja/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___pt",
    path: "/pt/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___zh-Hans",
    path: "/zh-Hans/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___zh-Hant",
    path: "/zh-Hant/privacy-policy",
    component: () => import("/src/Frontend/pages/privacy-policy.vue")
  },
  {
    name: "search-query___en",
    path: "/en/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___de",
    path: "/de/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___es",
    path: "/es/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___fr",
    path: "/fr/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___it",
    path: "/it/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___ja",
    path: "/ja/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___pt",
    path: "/pt/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___zh-Hans",
    path: "/zh-Hans/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "search-query___zh-Hant",
    path: "/zh-Hant/search/:query()",
    component: () => import("/src/Frontend/pages/search/[query].vue")
  },
  {
    name: "sign-in___en",
    path: "/en/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___de",
    path: "/de/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___es",
    path: "/es/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___fr",
    path: "/fr/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___it",
    path: "/it/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___ja",
    path: "/ja/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___pt",
    path: "/pt/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___zh-Hans",
    path: "/zh-Hans/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sign-in___zh-Hant",
    path: "/zh-Hant/sign-in",
    component: () => import("/src/Frontend/pages/sign-in.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___es",
    path: "/es/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___it",
    path: "/it/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___ja",
    path: "/ja/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___pt",
    path: "/pt/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___zh-Hans",
    path: "/zh-Hans/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "sitemap___zh-Hant",
    path: "/zh-Hant/sitemap",
    component: () => import("/src/Frontend/pages/sitemap.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___es",
    path: "/es/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___it",
    path: "/it/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___ja",
    path: "/ja/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___pt",
    path: "/pt/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___zh-Hans",
    path: "/zh-Hans/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___zh-Hant",
    path: "/zh-Hant/terms-and-conditions",
    component: () => import("/src/Frontend/pages/terms-and-conditions.vue")
  }
]